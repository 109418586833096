import { CSSProperties } from "typestyle/lib/types";

export const divider: CSSProperties = {
  width: "100%",
  height: 1,
  background: "#665656",
  marginTop: 32,
  marginBottom: 32,
  opacity: 0.2,
};
