import { NestedCSSProperties } from "typestyle/lib/types";

export const input: NestedCSSProperties = {
  borderRadius: 32,
  padding: 16,
  background: "#cfc7be",
  color: "#665656",
  border: 0,
  outline: 0,
  $nest: {
    "&::placeholder": {
      color: "#a39393",
    },
  },
};
