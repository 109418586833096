import { style } from "typestyle";
import CloseIcon from "mdi-react/CloseIcon";
import SendIcon from "mdi-react/SendIcon";
import { Input } from "../input/input";
import { Button } from "../button/button";
import * as styles from "./overlay.styles";
import { useCallback, useState } from "react";
import { sendData } from "../../send-data";

interface Props {
  readonly accepted: boolean;
  readonly open: boolean;
  readonly onClose: () => void;
}

export const Overlay = ({ accepted, open, onClose }: Props) => {
  const [name, setName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const send = useCallback(async () => {
    setLoading(true);
    await sendData(name, amount.length > 0 ? parseInt(amount) : 0, accepted);
    setLoading(false);
    setSent(true);
  }, [accepted, name, amount, setLoading, setSent]);

  let text = `Bedankt voor het reageren ${name}.`;

  if (accepted) {
    text += " En leuk dat je erbij bent!";
  } else {
    text += " Jammer dat je niet kan komen :(";
  }

  return (
    <div className={style(styles.overlay(open))}>
      {sent ? (
        <div className={style(styles.content)}>{text}</div>
      ) : (
        <>
          <CloseIcon
            className={style(styles.close)}
            size={32}
            onClick={onClose}
          />
          <Input
            type="text"
            placeholder="Naam"
            value={name}
            onChange={setName}
          />
          <div className={style(styles.spacer)} />
          {accepted && (
            <>
              <Input
                type="number"
                placeholder="Aantal personen"
                value={amount}
                onChange={setAmount}
              />
              <div className={style(styles.spacer)} />
            </>
          )}
          <Button
            disabled={
              loading || name.length === 0 || (accepted && amount.length === 0)
            }
            onClick={send}
          >
            <SendIcon />
            <div>Versturen</div>
          </Button>
        </>
      )}
    </div>
  );
};
