import { style } from "typestyle";
import * as styles from "./input.styles";

interface Props {
  readonly type: string;
  readonly placeholder: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
}

export const Input = ({ type, placeholder, value, onChange }: Props) => (
  <input
    className={style(styles.input)}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={(event) => onChange(event.target.value)}
  />
);
