import { style } from "typestyle";
import * as styles from "./timeline-item.styles";

interface Props {
  readonly time: string;
  readonly title: string;
  readonly icon: string;
  readonly position: "left" | "right";
}

export const TimelineItem = ({ time, title, icon, position }: Props) => (
  <div className={style(styles.timelineItem(position))}>
    <div style={{ alignSelf: position === "left" ? "flex-end" : "flex-start" }}>
      {position === "left" ? `${title} ${time}` : `${time} ${title}`}
    </div>
    <div className={style(styles.timelineIconWrapper)}>
      <img className={style(styles.timelineIcon)} src={icon} alt={title} />
    </div>
  </div>
);
