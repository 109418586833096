import { useState } from "react";
import { style } from "typestyle";
import HeartIcon from "mdi-react/HeartIcon";
import GoogleMapsIcon from "mdi-react/GoogleMapsIcon";
import EmoticonExcitedIcon from "mdi-react/EmoticonExcitedIcon";
import EmoticonCryIcon from "mdi-react/EmoticonCryIcon";
import title from "./assets/title.svg";
import subtitle from "./assets/subtitle.svg";
import { Button, Divider, Overlay, Timeline } from "./components";
import * as styles from "./app.styles";

export const App = () => {
  const [accepted, setAccepted] = useState<boolean>(false);
  const [rejected, setRejected] = useState<boolean>(false);

  const onMapsClick = () =>
    window.open(
      "https://www.google.nl/maps/place/Kasteel+Geldrop/@51.4250547,5.5572549,17z/data=!3m1!4b1!4m5!3m4!1s0x47c727a020942077:0x92dcfb08de66666d!8m2!3d51.4250547!4d5.5594489"
    );

  return (
    <div className={style(styles.wrapper)}>
      <div className={style(styles.container)}>
        <img
          className={style(styles.title)}
          src={title}
          alt="Erwin &apm; Arra"
        />
        <img
          className={style(styles.subtitle)}
          src={subtitle}
          alt="27 januari 2021"
        />
        <Divider />
        <div className={style(styles.heading)}>Uitnodiging</div>
        <div className={style(styles.content)}>
          Je bent uitgenodigd voor de bruiloft van Erwin Knoop en Dyanarra
          Fortich op 27 januari 2021 bij het Kasteel in Geldrop.
        </div>
        <GoogleMapsIcon
          className={style(styles.maps)}
          size={48}
          onClick={onMapsClick}
        />
        <Divider />
        <div className={style(styles.heading)}>Ben jij er bij?</div>
        <div className={style(styles.content)}>
          Laat via de onderstaande knoppen weten of je erbij bent zodat we daar
          rekening mee kunnen houden bij de reservering.
        </div>
        <div className={style(styles.buttons)}>
          <Button
            className={style(styles.button)}
            color="#87A979"
            onClick={() => setAccepted(true)}
          >
            <EmoticonExcitedIcon />
            <div>Ik ben er bij</div>
          </Button>
          <Button
            className={style(styles.button)}
            color="#a97979"
            onClick={() => setRejected(true)}
          >
            <EmoticonCryIcon />
            <div>Ik kan niet</div>
          </Button>
        </div>
        <Divider />
        <div className={style(styles.heading)}>Planning</div>
        <HeartIcon />
        <Timeline />
      </div>
      <Overlay
        accepted={accepted}
        open={accepted || rejected}
        onClose={() => {
          setAccepted(false);
          setRejected(false);
        }}
      />
    </div>
  );
};
