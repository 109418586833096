import { style } from "typestyle";
import rings from "../../assets/rings.svg";
import cake from "../../assets/cake.svg";
import party from "../../assets/party.svg";
import dinner from "../../assets/dinner.svg";
import { TimelineItem } from "../timeline-item/timeline-item";
import * as styles from "./timeline.styles";

export const Timeline = () => (
  <div className={style(styles.timeline)}>
    <div className={style(styles.timelineSpacer("left"))} />
    <TimelineItem time="14:00" title="Ceremonie" icon={rings} position="left" />
    <TimelineItem time="17:00" title="Eten" icon={dinner} position="left" />
    <div className={style(styles.timelineSpacer("left"))} />
    <div className={style(styles.timelineSpacer("left"))} />
    <div className={style(styles.timelineSpacer("right"))} />
    <div className={style(styles.timelineSpacer("right"))} />
    <TimelineItem time="15:00" title="Taart" icon={cake} position="right" />
    <TimelineItem time="19:00" title="Feest" icon={party} position="right" />
    <div className={style(styles.timelineSpacer("right"))} />
  </div>
);
