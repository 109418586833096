import { CSSProperties } from "typestyle/lib/types";

export const timeline: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  marginTop: 4,
  width: 256,
  height: 448,
};

export const timelineSpacer = (position: "left" | "right"): CSSProperties => ({
  width: 128,
  height: 64,
  borderLeft: position === "right" ? "1px solid #000" : undefined,
  borderRight: position === "left" ? "1px solid #000" : undefined,
});
