import { CSSProperties } from "typestyle/lib/types";

export const timelineItem = (position: "left" | "right"): CSSProperties => ({
  display: "flex",
  flexDirection: "column",
  width: 128,
  height: 128,
  borderLeft: position === "right" ? "1px solid #000" : undefined,
  borderRight: position === "left" ? "1px solid #000" : undefined,
  borderTop: "2px solid #000",
  padding: 8,
  fontSize: 12,
});

export const timelineIconWrapper: CSSProperties = {
  display: "flex",
  width: "100%",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
};

export const timelineIcon: CSSProperties = {
  display: "block",
  width: 64,
};
