import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCzLXCqBYO_uwJN-HHkfiEDP-UI1y3fNlY",
  authDomain: "wedding-invitation-deb10.firebaseapp.com",
  projectId: "wedding-invitation-deb10",
  storageBucket: "wedding-invitation-deb10.appspot.com",
  messagingSenderId: "888746850063",
  appId: "1:888746850063:web:ec1f308a5ecae67955707a",
};

firebase.initializeApp(config);

export { firebase };
