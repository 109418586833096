import { CSSProperties, NestedCSSProperties } from "typestyle/lib/types";

export const wrapper: CSSProperties = {
  position: "relative",
  width: "100%",
  height: "100%",
};

export const container: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: 32,
  paddingLeft: 32,
  paddingRight: 32,
};

export const title: CSSProperties = {
  width: 320,
  marginTop: 32,
};

export const subtitle: CSSProperties = {
  width: 128,
  marginTop: 32,
};

export const heading: CSSProperties = {
  fontFamily: "'Merriweather', serif",
  maxWidth: 320,
  fontSize: 24,
  fontWeight: 700,
  textAlign: "center",
  marginBottom: 16,
};

export const content: CSSProperties = {
  maxWidth: 320,
  textAlign: "center",
  fontSize: 14,
  lineHeight: 1.7,
};

export const buttons: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  maxWidth: 320,
  marginTop: 16,
};

export const button: NestedCSSProperties = {
  flex: 1,
  $nest: {
    "&:first-child": {
      marginRight: 16,
    },
  },
};

export const maps: CSSProperties = {
  marginTop: 16,
  cursor: "pointer",
};
