import { firebase } from "./firebase";

export async function sendData(
  name: string,
  amount: number,
  accepted: boolean
) {
  await firebase.auth().signInAnonymously();
  const db = firebase.firestore();

  await db.collection("invitationResponses").add({
    name,
    amount,
    accepted,
  });
}
