import { CSSProperties } from "typestyle/lib/types";

export const overlay = (visible: boolean): CSSProperties => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: "#fff",
  display: visible ? "flex" : "none",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "center",
  padding: 32,
});

export const close: CSSProperties = {
  position: "fixed",
  top: 32,
  right: 32,
  cursor: "pointer",
};

export const spacer: CSSProperties = {
  height: 16,
};

export const content: CSSProperties = {
  maxWidth: 320,
  textAlign: "center",
  fontSize: 14,
  lineHeight: 1.7,
};
