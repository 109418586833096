import { NestedCSSProperties } from "typestyle/lib/types";

export const button = (
  color?: string,
  disabled?: boolean
): NestedCSSProperties => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  background: color || "#665656",
  color: "#fff",
  fontSize: 12,
  fontWeight: 600,
  borderRadius: 32,
  padding: 16,
  cursor: "pointer",
  opacity: disabled ? 0.5 : 1,
  $nest: {
    "> *:first-child": {
      marginRight: 8,
    },
  },
});
