import { normalize, setupPage } from "csstips";
import { StrictMode } from "react";
import { render } from "react-dom";
import { cssRule } from "typestyle";

import { App } from "./app";
import reportWebVitals from "./reportWebVitals";

normalize();
setupPage("#root");
cssRule("body", {
  background: "#F8F1EC",
  fontFamily: "'Open Sans', sans-serif",
  color: "#665656",
});

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
