import { ReactNode } from "react";
import { style } from "typestyle";
import * as styles from "./button.styles";

interface Props {
  readonly className?: string;
  readonly color?: string;
  readonly disabled?: boolean;
  readonly children: ReactNode;
  readonly onClick: () => void;
}

export const Button = ({
  className,
  color,
  disabled,
  children,
  onClick,
}: Props) => (
  <div
    className={`${style(styles.button(color, disabled))} ${className}`}
    onClick={!disabled ? onClick : undefined}
  >
    {children}
  </div>
);
